<div class="grid-container">
    <a href="https://airkm.datascience.cmu.ac.th/">
        <img src="assets/image/banner.png" width="350" height="120">
    </a>
</div>
<div class="grid-container">
    <div class="">
        <h1 class="sansserif">คุณภาพอากาศ</h1>
    </div>
    <div class="row">
        <div class="col-md-4 col-12">
            <div class="custom-card">
                <div class="custom-card-header" [ngClass]="currentCardHeadColor">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="btn-group custom-group-btn" role="group">
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(4)" class="btn">Air4Thai</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(3)" class="btn">AQICN</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(1)" class="btn">CMAQI</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(2)" class="btn">IQAir</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" [ngClass]="currentCardBodyColor">
                    <div class="header-text">
                        <div>คุณภาพอากาศวันนี้</div>
                        <div>
                            <h2 [ngClass]="currentCardTxtColor">{{currentAQI}}</h2>
                        </div>
                    </div>
                </div>
                <div class="p-2">
                    <div class="status-text" [ngClass]="currentCardFooterColor">
                        <a href="https://airkm-admin.datascience.cmu.ac.th/en/AQI" target="_blank" rel="noopener noreferrer">{{currentLevel}}</a>
                    </div>
                    <div class="text-left">
                        {{currentDescribtion}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-12 mt-4 mt-md-0">
            <div class="custom-card p-3 ">
                <div class="header-text pt-0">
                    <div>ค่ามลพิษในอากาศ</div>
                </div>
                <div class="sub-header-text" style="font-style: italic;">
                    *อ้างอิงค่ามลพิษจาก air4thai.pcd.go.th
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/NO2" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">NO2</div>
                                </a>
                                <div class="normal-label">ไนโตรเจนไดออกไซด์</div>
                                <div class="gas-label">{{no2}}</div>
                                <div class="normal-label">ppb</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/SO2" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">SO2</div>
                                </a>
                                <div class="normal-label">ซัลเฟอร์ไดออกไซด์</div>
                                <div class="gas-label">{{so2}}</div>
                                <div class="normal-label">ppb</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 mt-2 mt-md-0">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/O3" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">O3</div>
                                </a>
                                <div class="normal-label">โอโซน</div>
                                <div class="gas-label">{{o3}}</div>
                                <div class="normal-label">ppb</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 mt-2">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/CarbonMonoxide" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">CO</div>
                                </a>
                                <div class="normal-label">คาร์บอนมอนออกไซด์</div>
                                <div class="gas-label">{{co}}</div>
                                <div class="normal-label">ppm</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 mt-2">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/PM10" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">PM10</div>
                                </a>
                                <div class="normal-label">ฝุ่นขนาดไม่เกิน 10 ไมครอน</div>
                                <div class="gas-label">{{pm10}}</div>
                                <div class="normal-label">ug/m3</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 mt-2">
                        <div class="custom-gray-circle text-center">
                            <div class="abs-center">
                                <a href="{{wikiLink}}/PM2-5" target="_blank" rel="noopener noreferrer">
                                    <div class="gas-label">PM2.5</div>
                                </a>
                                <div class="normal-label">ฝุ่นขนาดไม่เกิน 2.5 ไมครอน</div>
                                <div class="gas-label">{{pm25}}</div>
                                <div class="normal-label">ug/m3</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class='p-3' style="word-wrap:break-word;">
                    <div>{{currentRec}}</div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row pb-4">
        <div class="col-12">
            <div class="custom-card p-3 mt-4">
                <div class="header-text pt-0">
                    <div>สภาพอากาศ</div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 text-center">
                        <div class="header-text pt-1">อุณหภูมิ</div>
                        <div class="temp-text green-font">{{temp}}</div>
                        <div>องศาเซลเซียส (C)</div>
                        <div class="mt-2">สูงสุด</div>
                        <div class="red-text-no-margin-btm subheader-text mt-1">{{maxTemp}}</div>
                        <div class="mt-2">ต่ำสุด</div>
                        <div class="blue-text-no-margin-btm subheader-text mt-1">{{minTemp}}</div>
                    </div>
                    <div class="col-12 col-md-8 mt-0 mt-md-2">
                        <div class="sky-content">{{sky}}</div>
                        <!-- <mat-card-content class="dashboard-card-content">{{sky}}</mat-card-content> -->
                        <div class="row mx-0 mt-3">
                            <div class="col-12 col-md-4 custom-detail-card">
                                <div class="subheader-text">ความชื้น</div>
                                <div class="header-text pt-0">{{humidity}}</div>
                                <div>เปอร์เซ็นต์ (%)</div>
                            </div>
                            <div class="col-12 col-md-4 mt-1 mt-md-0 custom-detail-card">
                                <div class="subheader-text">ความกดอากาศ</div>
                                <div class="header-text pt-0">{{pressure}}</div>
                                <div>มิลลิบาร์ (mb)</div>
                            </div>
                            <div class="col-12 col-md-4 mt-1 mt-md-0 custom-detail-card">
                                <div class="subheader-text">ปริมาณน้ำฝน</div>
                                <div class="header-text pt-0">{{rain}}</div>
                                <div>มิลลิเมตร (mm)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AQI Index Card -->
    <!-- <mat-grid-list cols="9" rowHeight="470px">
        <mat-grid-tile [colspan]="3" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header [ngClass]="currentCardHeadColor">
                    <mat-button-toggle-group class="btn-magin" name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(4)">Air4thai</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(3)">AQICN</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(1)">CMAQI</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(2)">IQAir</mat-button-toggle>
                    </mat-button-toggle-group>
                </mat-card-header>
                <mat-card-content [ngClass]="currentCardBodyColor">
                    <br><br>
                    <div>
                        <h2>คุณภาพอากาศวันนี้</h2>
                    </div>
                    <div>
                        <h2 [ngClass]="currentCardTxtColor">{{currentAQI}}</h2>
                    </div>
                    <br>
                </mat-card-content>
                <mat-card-footer class="dashboard-card-footer">
                    <div>
                        <h5 [ngClass]="currentCardFooterColor">{{currentLevel}}</h5>
                    </div>
                    <div class="text-left">
                        {{currentDescribtion}}
                    </div>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile> -->
    <!-- Compound Card-->
    <!-- <mat-grid-tile [colspan]="6" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        <br>
                        <div>
                            <h3>ค่ามลพิษในอากาศ</h3>
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <mat-card-content class="dashboard-card-content">
                        <mat-grid-list cols="6" rowHeight="170px">
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/NO2">
                                            <h4>NO2</h4>
                                        </a>ไนโตรเจนไดออกไซด์
                                        <h2> {{no2}} </h2> ppb
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/SO2" target="_blank" rel="noopener noreferrer">
                                            <h4>SO2</h4>
                                        </a>ซัลเฟอร์ไดออกไซด์
                                        <h2> {{so2}} </h2> ppb
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/O3" target="_blank" rel="noopener noreferrer">
                                            <h4>O3</h4>
                                        </a>โอโซน
                                        <h2>{{o3}}</h2>ppb
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card-content>
                    <mat-card-content class="dashboard-card-content">
                        <mat-grid-list cols="6" rowHeight="170px">
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/CarbonMonoxide" target="_blank" rel="noopener noreferrer">
                                            <h4>CO</h4>
                                        </a>คาร์บอนมอนออกไซด์
                                        <h2> {{co}} </h2> ppm
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/PM10" target="_blank" rel="noopener noreferrer">
                                            <h4>PM10</h4>
                                        </a>ฝุ่นขนาดไม่เกิน 10 ไมครอน
                                        <h2> {{pm10}} </h2> ug/m3
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="1">
                                <mat-card class="dashboard-card-circle gray-bg">
                                    <mat-card-content class="dashboard-card-content">
                                        <a href="{{wikiLink}}/PM2-5" target="_blank" rel="noopener noreferrer">
                                            <h4>PM2.5</h4>
                                        </a>ฝุ่นขนาดไม่เกิน 2.5 ไมครอน
                                        <h2> {{pm25}} </h2> ug/m3
                                    </mat-card-content>
                                </mat-card>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card-content>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list> -->
    <!-- Weather -->
    <!-- <mat-grid-list cols="9" rowHeight="400px">
        <mat-grid-tile [colspan]="9" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        <h2>สภาพอากาศ</h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <mat-grid-list cols="9" rowHeight="150px" class="right-line">
                        <mat-grid-tile [colspan]="3" [rowspan]="2">
                            <mat-card-content class="dashboard-card-content ">
                                <h4>อุณหภูมิ</h4>
                                <h1 class="green-font"> {{temp}} </h1> องศาเซลเซียส (C) <br> สูงสุด
                                <h5 class="red-text-no-margin-btm">{{maxTemp}}</h5>
                                ต่ำสุด
                                <h5 class="blue-text-no-margin-btm">{{minTemp}}</h5>
                            </mat-card-content>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="6" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content class="dashboard-card-content">{{sky}}</mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="gray-bg">
                            <mat-card-content>
                                <h4>ความชื้น</h4>
                                <h2>{{humidity}}</h2> เปอร์เซ็นต์ (%)
                            </mat-card-content>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="gray-bg">
                            <mat-card-content>
                                <h4>ความกดอากาศ </h4>
                                <h2>{{pressure}}</h2> มิลลิบาร์ (mb)
                            </mat-card-content>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="2" [rowspan]="1" class="gray-bg">
                            <mat-card-content>
                                <h4>ปริมาณน้ำฝน </h4>
                                <h2>{{rain}}</h2> มิลลิเมตร (mm)
                            </mat-card-content>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list> -->
    <!-- Map
    <agm-map [latitude]="lat" [longitude]="lng">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
    </agm-map> -->
</div>