<div class="grid-container">
    <a href="https://airkm.datascience.cmu.ac.th/">
        <img src="assets/image/banner.png" width="350" height="120">
    </a>
</div>
<div class="grid-container">
    <div class="">
        <h1>สภาพอากาศ</h1>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="custom-card">
                <div class="custom-card-header" [ngClass]="currentCardHeadColor">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="btn-group custom-group-btn" role="group">
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(4)" class="btn">Air4Thai</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(3)" class="btn">AQICN</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(1)" class="btn">CMAQI</button>
                                <button type="button" [ngClass]="currentCardBodyColor" (click)="OnClickSource(2)" class="btn">IQAir</button>
                            </div>
                        </div>
                        <div class="col-lg-12 text-right">
                            <div class="date-label">{{day}}</div>
                        </div>
                    </div>
                </div>
                <div class="text-center" [ngClass]="currentCardBodyColor">
                    <div class="header-text">
                        <div>คุณภาพอากาศวันนี้</div>
                        <div>
                            <h2 [ngClass]="currentCardTxtColor">{{currentAQI}}</h2>
                        </div>
                    </div>
                </div>
                <div class="p-2">
                    <div>
                        <div class="status-text" [ngClass]="currentCardFooterColor">
                            <a href="https://airkm-admin.datascience.cmu.ac.th/en/AQI" target="_blank">{{currentLevel}}</a>
                        </div>
                    </div>
                    <div class="text-left">
                        {{currentDescribtion}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 mt-4 mt-md-0">
            <div class="custom-card p-3 ">
                <div class="header-text pt-0">
                    <div>คำแนะนำด้านสุขภาพ</div>
                </div>
                <div class='p-3' style="word-wrap:break-word;">
                    <div>{{currentRec}}</div>
                </div>
                <div class='p-3' style="word-wrap:break-word;">
                    <a class="footer-text">หมายเหตุ อ้างอิงดัชนีคุณภาพอากาศจาก air4thai.pcd.go.th</a>
                    <a mat-raised-button color="primary" href="https://airkm-admin.datascience.cmu.ac.th/en/protocol" target="_blank" class="footer-btn">อ่านเพิ่มเติม</a>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="custom-card p-3">
                <div class="header-text pt-0">
                    <div>พยากรณ์ค่า PM 2.5 ด้วยแบบจำลองท้องถิ่น (มคก./ลบ.ม.)</div>
                </div>
                <div class="row">
                    <!-- <li *ngFor="let item of (localPredicts|keyvalue)">
                        {{item.key | date: 'short'}} {{item.value}}
                    </li> -->
                    <div class="col-4 col-md text-center" style="padding-left: 1px; padding-right: 1px;" *ngFor="let day of localPredicts">
                        <div class="a" style="height: 60px">{{day[0].date | date: 'dd'}}</div>
                        <div class="b">{{day[0].date | date: 'MMMM'}}</div>
                        <!-- <div class="b" *ngFor="let hour of day"><span class="text-muted">{{hour.date | date: 'HH:mm'}} น.</span> {{hour.pm25 | number: '1.0-0'}}</div> -->
                        <div class="b" *ngFor="let hour of day" [ngClass]="hour.color"><span class="text-muted">{{hour.date | date: 'HH:mm':'+0000'}} น.</span> {{hour.pm25 | number: '1.0-0'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="custom-card p-3">
                <div class="header-text pt-0">
                    <div>พยากรณ์คุณภาพอากาศ</div>
                </div>
                <div class="row">
                    <div class="col-4 col-md text-center" style="padding-left: 1px; padding-right: 1px;" *ngFor="let tile of airTiles; let i = index">
                        <div class="a" style="height: 60px">{{tile.day}}</div>
                        <div class="b">{{tile.month}}</div>
                        <div [ngClass]="tile.color" style="line-height: 120px">{{tile.value}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="custom-card p-3">
                <div class="header-text pt-0">
                    <div>สภาพอากาศ</div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-3 mt-2">
                        <div class="weather-card text-center pt-2">{{sky}}</div>
                    </div>
                    <div class="col-6 col-md-3 mt-2">
                        <div class="weather-card text-center">
                            <div class="pt-3">ความชื้น</div>
                            <div class="weather-text">{{humidity}}</div>
                            <div>เปอร์เซ็น (%)</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mt-2">
                        <div class="weather-card text-center">
                            <div class="pt-3">ปริมาณน้ำฝน</div>
                            <div class="weather-text">{{rain}}</div>
                            <div>มิลลิเมตร (mm)</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mt-2">
                        <div class="weather-card text-center">
                            <div class="pt-3">ความกดอากาศ</div>
                            <div class="weather-text">{{pressure}}</div>
                            <div>มิลลิบาร์ (mb)</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mt-2 mt-md-4">
                        <div class="weather-card text-center">
                            <div class="pt-3">อุณหภูมิ</div>
                            <div class="weather-text m-0 orange-text-no-margin-btm">{{temp}}</div>
                            <div>องศาเซลเซียส (C)</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-9 mt-2 mt-md-4">
                        <div class="weather-card text-center gray-bg">
                            <div class="pt-3">โอกาสที่ฝนจะตก</div>
                            <div class="weather-text">
                                <h1>{{rain_pop}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="custom-card p-3">
                <div class="header-text pt-0">
                    <div>พยากรณ์อากาศ</div>
                </div>
                <div class="row">
                    <div class="col-4 col-md text-center" style="padding-left: 1px; padding-right: 1px;" *ngFor="let tile of weatherTiles; let i = index">
                        <div class="a" style="height: 60px">{{tile.day}}</div>
                        <div class="b">{{tile.month}}</div>
                        <div [style.background]="tile.color" style="line-height: 120px">{{tile.value}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AQI Index card -->
    <!-- <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header [ngClass]="currentCardHeadColor">
                    <mat-button-toggle-group class="btn-magin" name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(4)">Air4thai</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(3)">AQICN</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor " (click)="OnClickSource(1)">CMAQI</mat-button-toggle>
                        <mat-button-toggle [ngClass]="currentCardBodyColor" (click)="OnClickSource(2)">IQAir</mat-button-toggle>
                    </mat-button-toggle-group>
                    <div class="date">
                        {{day}}
                    </div>
                </mat-card-header>
                <mat-card-content [ngClass]="currentCardBodyColor">
                    <br>
                    <div>
                        <h2>คุณภาพอากาศวันนี้</h2>
                    </div>
                    <div>
                        <h2 [ngClass]="currentCardTxtColor">{{currentAQI}}</h2>
                    </div>
                </mat-card-content>
                <mat-card-footer class="dashboard-card-footer">
                    <div>
                        <h5 [ngClass]="currentCardFooterColor">{{currentLevel}}</h5>
                    </div>
                    <div class="text-left">
                        {{currentDescribtion}}
                    </div>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile> -->
    <!-- Health recommendation card -->
    <!-- <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        <div>
                            <h2>คำแนะนำด้านสุขภาพ</h2>
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div style="word-wrap:break-word; text-align:justify;">
                        <h6>{{currentRec}}</h6>
                    </div>
                </mat-card-content>
                <mat-card-footer style="margin: 30px 30px 30px 30px;">
                    <div>
                        <a class="footer-text">หมายเหตุ อ้างอิงคุณภาพจาก air4thai.pcd.go.th</a>
                        <a mat-raised-button color="primary" href="https://airkm-admin.datascience.cmu.ac.th/en/AQI" target="_blank" class="footer-btn">อ่านเพิ่มเติม</a>
                    </div>
                    <mat-card-actions class="dashboard-card-footer">
                    </mat-card-actions>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list> -->
    <!-- AQI Forecast card -->
    <!-- <mat-grid-list cols="7" rowHeight="350px">
        <mat-grid-tile [colspan]="7" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        <h2>พยากรณ์คุณภาพอากาศ</h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <mat-grid-list cols="6" rowHeight="60px">
                        <mat-grid-tile *ngFor="let tile of airTiles; let i = index" [colspan]="tile.cols" [rowspan]="tile.rows">
                            <div class="a">{{tile.day}}</div> <br>
                        </mat-grid-tile>
                        <mat-grid-tile *ngFor="let tile of airTiles; let i = index" [colspan]="tile.cols" [rowspan]="tile.rows">
                            <div class="b">{{tile.month}}</div>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list cols="6" rowHeight="120px">
                        <mat-grid-tile *ngFor="let tile of airTiles; let i = index" [colspan]="tile.cols" [rowspan]="tile.rows" [ngClass]="tile.color">
                            {{tile.value}}
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list> -->
    <!-- Weahter card -->
    <!-- <mat-grid-list cols="4" rowHeight="400">
        <mat-grid-tile [colspan]="4" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        <h2>สภาพอากาศ</h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <mat-grid-list cols="4" rowHeight="150px">
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content>{{sky}}</mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content>
                                    <br>
                                    <h6>ความชื้น</h6>
                                    <h2>{{humidity}}</h2>
                                    เปอร์เซ็น (%)
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content>
                                    <br>
                                    <h6>ปริมาณน้ำฝน</h6>
                                    <h2>{{rain}}</h2>
                                    มิลลิเมตร (mm)
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content>
                                    <br>
                                    <h6>ความกดอากาศ</h6>
                                    <h2> {{pressure}} </h2>
                                    มิลลิบาร์ (mb)
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
                <mat-card-content class="dashboard-card-content">
                    <mat-grid-list cols="4" rowHeight="150px">
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content class="dashboard-card-content">
                                    <br>
                                    <h6>อุณหภูมิ</h6>
                                    <h2 class="orange-text-no-margin-btm">{{temp}}</h2>
                                    องศาเซลเซียส (C)
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="3" [rowspan]="1">
                            <mat-card class="dashboard-card gray-bg">
                                <mat-card-content>
                                    <br>
                                    <h6 class="dashboard-card-footer">โอกาสที่ฝนจะตก</h6>
                                    <br> {{rain_pop}}
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list> -->
    <!-- Weahter Forecast card -->
    <!-- <mat-grid-list cols="7" rowHeight="450px">
        <mat-grid-list cols="7" rowHeight="450px">
            <mat-grid-tile [colspan]="7" [rowspan]="1">
                <mat-card class="dashboard-card ">
                    <mat-card-header>
                        <mat-card-title>
                            <h2>พยากรณ์อากาศ</h2>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content ">
                        <mat-grid-list cols="6" rowHeight="60px">
                            <mat-grid-tile *ngFor="let tile of weatherTiles; let i=i ndex " [colspan]="tile.cols " [rowspan]="tile.rows ">
                                <div class="a">{{tile.day}}</div> <br>
                            </mat-grid-tile>
                            <mat-grid-tile *ngFor="let tile of weatherTiles; let i=i ndex " [colspan]="tile.cols " [rowspan]="tile.rows ">
                                <div class="b">{{tile.month}}</div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-grid-list cols="6" rowHeight="120px">
                            <mat-grid-tile *ngFor="let tile of weatherTiles; let i=i ndex " [colspan]="tile.cols " [rowspan]="tile.rows " [style.background]="tile.color ">
                                <span>{{tile.value}}</span>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-grid-list> -->
</div>