<mat-drawer-container class="example-container grad1" autosize>
    <div class="example-sidenav-content">
        <div class="top-left-menu">
            <button mat-icon-button (click)="drawer.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="top-right-menu">
            <nav>
                <a class="button black-text" routerLink="/home" routerLinkActive="activebutton">สภาพอากาศ</a>
                <a class="button black-text" routerLink="/dashboard" routerLinkActive="activebutton">คุณภาพอากาศ</a>
                <a class="button black-text" routerLink="/news" routerLinkActive="activebutton">ข่าวสาร</a>
                <a class="button black-text" href="https://airkm-admin.datascience.cmu.ac.th/" target="_blank" rel="noopener noreferrer">คลังข้อมูล</a>
            </nav>
        </div>
        <router-outlet></router-outlet>
    </div>

    <mat-drawer #drawer class="example-sidenav sidenav" mode="over">
        <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
        <p><button type="button" mat-button class="block" routerLink="/home">ตรวจสอบสภาพอากาศ</button></p>
        <p><a type="button" mat-button class="block" href="https://airkm-admin.datascience.cmu.ac.th/en/curriculum" target="_blank" rel="noopener noreferrer">ข้อมูลหลักสูตร</a></p>
        <p><button type="button" mat-button class="block" routerLink="/contact">ติดต่อผู้เชี่ยวชาญ</button></p>
        <p><button type="button" mat-button class="block" routerLink="/about">เกี่ยวกับ</button></p>
        <p><button type="button" mat-button class="block" routerLink="/admin">การตั้งค่า</button></p>
    </mat-drawer>
</mat-drawer-container>