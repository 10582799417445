<div class="grid-container">
    <a href="https://airkm.datascience.cmu.ac.th/">
        <img src="assets/image/banner.png" width="300" height="100">
    </a>
</div>
<div class="grid-container">
    <div class="">
        <h1>ติดต่อผู้เชี่ยวชาญ</h1>
    </div>
    <div class="bg-ivory">
        <mat-grid-list [cols]="breakpoint" rowHeight="230" (window:resize)="onResize($event)">
            <mat-grid-tile *ngFor="let person of people" [colspan]="1" [rowspan]="1">
                <mat-card class="dashboard-card">
                    <mat-card-content>
                        <div>
                            <a href="{{wikiLink}}/{{person.id}}" target="_blank" rel="noopener noreferrer">
                                <h5>{{person.name}}</h5>
                            </a>
                            <h6>{{person.memberof}}</h6>
                            <h6>{{person.position}}</h6>
                            Email: <a href="mailto: {{person.email}}">{{person.email}}</a>
                            <br>โทร {{person.tel}}
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>