<div class="grid-container">
    <a href="https://airkm.datascience.cmu.ac.th/">
        <img src="assets/image/banner.png" width="300" height="100">
    </a>
</div>
<div class="grid-container">
    <div class="">
        <h1>การตั้งค่า</h1>
    </div>
    <mat-grid-list cols="4" rowHeight="700">
        <mat-grid-tile [colspan]="4" [rowspan]="1">
            <mat-card class="dashboard-card" >
                <mat-card-content class="dashboard-card-content">
                    <mat-grid-list cols="4" (window:resize)="onResize($event)">
                        <mat-grid-tile [colspan]="breakpoint" [rowspan]="2">
                            <mat-card class="dashboard-card" style="overflow-y:auto; padding-bottom: 2em;">
                                <mat-card-header>
                                    <h2>Health Reccomendation</h2>
                                </mat-card-header>
                                <mat-card-content class="btn-center">
                                    <!-- <form #myform="ngForm" (ngSubmit)="onSubmit(myform)"> -->
                                    <table class="table ">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Color</th>
                                                <th scope="col">Recommendation</th>
                                            </tr>
                                        </thead>
                                        <tr>
                                            <td style="background-color: red;"></td>
                                            <td><textarea class="form-control" row="3" [(ngModel)]="red_rec"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: orange;"></td>
                                            <td><textarea class="form-control" row="3" [(ngModel)]="ora_rec"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: yellow;"></td>
                                            <td><textarea class="form-control" row="3" [(ngModel)]="yel_rec"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: yellowgreen;"></td>
                                            <td><textarea class="form-control" row="3" [(ngModel)]="gre_rec"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #69C9FA;"></td>
                                            <td><textarea class="form-control" row="3" [(ngModel)]="blu_rec"></textarea></td>
                                        </tr>
                                    </table>
                                    <button mat-raised-button color="primary" (click)="submit()">Submit</button>
                                    <!-- </form> -->
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="breakpoint2" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content class="btn-center">
                                    <div class='abs-btn'>
                                        <a mat-raised-button color="primary" href="{{wikiURL}}" target="_blank">Content Mangement</a>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="breakpoint2" [rowspan]="1">
                            <mat-card class="dashboard-card">
                                <mat-card-content class="btn-center">
                                    <div class='abs-btn'>
                                        <button mat-raised-button color="primary">History Data</button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>