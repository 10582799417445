<div style="background-color: #FFFFFF; border-radius: 5px; margin-top:30px; width:90%; max-width: 1150px; margin-left: auto; margin-right:auto">
    <div style="width:90%; margin-left:auto; margin-right:auto; padding-top: 30px;">
        <h1>เกี่ยวกับ</h1>
    </div>
    <div style="word-wrap:break-word; text-align:justify; margin-top: 30px; margin-left: auto; margin-right:auto; width:90%">
        <p style="text-indent: 50px">
            ฝุ่นควันเป็นปัญหาของภาคเหนือที่เกิดขึ้นมาเป็นเวลานานตั้งแต่ในปี พ.ศ. 2550 และทวีความรุนแรงขึ้นทุกปี และส่งผลกระทบต่อสุขภาพอย่างมาก ทำให้รัฐบาลได้ประกาศให้ปัญหาหมอกควันภาคเหนือเป็นวาระแห่งชาติ และในปี พ.ศ. 2562 ที่ผ่านมา ค่าฝุ่นควันของภาคเหนือได้สูงจนเป็นอันดับหนึ่งของโลก
            หากนับย้อนหลังไป ช่วงเวลาที่มีฝุ่นควันนั้น จะอยู่ในช่วง 1-2 เดือน แต่ในปัจจุบันช่วงเวลาการเกิดปัญหาฝุ่นควันมีระยะเวลาที่ยาวนานยิ่งขึ้น จนในปี พ.ศ. 2562 เป็นปีที่มีช่วงฝุ่นควันยาวนานถึง 5 เดือน โดยฝุ่นควันที่เกิดขึ้นนั้นส่งผลกระทบต่อประชาชนในท้องถิ่นอย่างมาก
            ทั้งทางด้านสุขภาพ สังคมและเศรษฐกิจ ดังเช่น ปัญหาฝุ่นละอองที่ส่งผลกระทบทางตรงลำดับแรกคือผลกระทบต่อสุขภาพ ผู้ป่วยส่วนใหญ่จะเป็น เด็กเล็ก หญิงตั้งครรภ์ คนชรา และผู้มีโรคประจำตัว โดยมีการรายงานว่า 8 จังหวัดภาคเหนือมีผู้ได้รับผลกระทบทางด้านสุขภาพมากกว่า
            75,000 คน ซึ่งโรคที่พบมากคือ โรคทางเดินหายใจทุกชนิด โรคหัวใจและหลอดเลือดโรคตา โดยหากเป็นคนวัยทำงาน ก็จะส่งผลต่อประสิทธิภาพในการทำงานลดลง และลำดับต่อมาคือผลกระทบต่อการท่องเที่ยว ซึ่งเป็นภาคธุรกิจหลักที่นำรายได้เข้าสู่ประเทศไทย มีการประเมินในเบื้องต้นว่า
            ผลกระทบทางเศรษฐกิจจากปัญหาฝุ่นควันจะสร้างความเสียหายทางเศรษฐกิจคิดเป็นมูลค่าสูงถึง 5,000-10,000 ล้านบาท
        </p>
        <p style="text-indent: 50px">
            ข้อมูลที่เกี่ยวข้องกับฝุ่นควันมีที่มาหลากหลายแหล่ง โดยแต่ละที่มามีความซ้อนทับกันของข้อมูลกันไม่มากก็น้อย อาทิเช่น ข้อมูลปริมาณฝุ่นควันที่มีดัชนีคุณภาพอากาศ (Air Quality Index : AQI) (PM10, PM2.5, O3, CO, NO2 และ SO3) ข้อมูลจุดความร้อน (Hot spot) ปริมาณลม
            อุณหภูมิ ความชื้น ปริมาณน้ำฝน นอกจากนี้ยังมีข้อมูลทางด้านสาธารณสุข เช่น จำนวนผู้ป่วย โรคที่มีต้นเหตุจากฝุ่นควัน สถานที่ให้บริการ รวมไปถึงเศรษฐกิจและสังคม ในด้านรายได้และรายจ่าย ปริมาณนักท่องเที่ยว ผลกระทบอื่นๆที่มาจากฝุ่นควัน ทางกลุ่มบูรณาการวิชาการทางด้านวิทยาการข้อมูล
        </p>
        <p style="text-indent: 50px">
            มหาวิทยาลัยเชียงใหม่ มีจุดมุ่งหมายที่จะรวบรวมข้อมูลที่เกี่ยวข้องกับฝุ่นควันนภาคเหนือ มีอยู่จำนวนมากและหลากหลายฐานข้อมูล เพื่อทำการศึกษาข้อมูลที่เกี่ยวข้องกับการเกิดและการควบคุมฝุ่นควันในภาคเหนือ และพัฒนาระบบจัดการบูรณาการข้อมูล (Data Integration) ฝุ่นควันทั้งหมด
            และเพื่อออกแบบระบบรายงานสำหรับการเผยแพร่ให้กับกลุ่มผู้ใช้ข้อมูลและผู้ที่สนใจให้สามารถเข้าถึงได้ง่าย และแสดงถึงสถานการณ์ปัจจุบันได้ พร้อมทั้งยังสามารถเตรียมตัวรับมือกับสถานการณ์ได้และสามารถเป็นที่ให้ความรู้ความเข้าใจจากผู้มีองค์ความรู้โดยตรง
        </p>
    </div>
    <div style="margin: 50px; padding-bottom: 30px;">
        <h5>เอกสารอ้างอิง</h5>
        - Air Quality Index (AQI). (2562, มิถุนายน). Retrieved from https://aqicn.org/city/chiang-mai/- BBC. (2562). Retrieved from https://www.bbc.com/thai/thailand-47550696
        <br>- BBC. (2562). Retrieved from https://www.bbc.com/thai/47534868
        <br>- Locht, A. D. (2561, สิงหาคม). What is data integration? Retrieved from IBM: https://www.ibm.com/analytics/data-integration
        <br>- ทีมเศรษฐกิจ. (2562, มกราคม 28). เปิดผลกระทบ "วิกฤติฝุ่นพิษ " ซ้ำเติมเศรษฐกิจไทยหนักกว่าที่คิด. Retrieved from ไทยรัฐฉบับพิมพ์: https://www.thairath.co.th/news/business/1480790
        <br>- ประชาชาติธุรกิจ ออนไลน์. (2562, มีนาคม 28). Retrieved from https://www.prachachat.net/local-economy/news-308189
        <br>- มติชนออนไลน์. (2562, เมษายน 4). หมอกควันภาคเหนือกระทบหนัก โรงแรม-ท่องเที่ยววูบ. Retrieved from มติชนออนไลน์: https://www.matichon.co.th/region/news_1436805
        <br>- ศูนย์อำนวยการสั่งการแก้ไขปัญหาหมอกควันและไฟป่าจังหวัดเชียงใหม่. (2562, มิถุนายน). สำนักงานทรัพยากรธรรมชาติและสิ่งแวดล้อมจังหวัดเชียงใหม่. Retrieved from http://chiangmai.mnre.go.th/th/index
    </div>
</div>