<div class="grid-container">
    <div class="">
        <h1 class="sansserif">ข่าวสาร</h1>
    </div>

    <div class="">
        <h2 class="sansserif">ข่าวล่าสุด</h2>
    </div>
    <div class="carousel-center">
        <ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img [src]="imgHeadline" class="mh-100" style="width: 100%; height: 60vh">
                </div>
                <div class="carousel-caption">
                    <a [href]="pathHeadline" target="_blank">
                        <div class="link">
                            <h3>{{headline}}</h3>
                        </div>
                    </a>
                    <p>{{captionHeadline}}</p>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>

    <br>
    <h2>ข่าวย้อนหลัง</h2>
    <div>
        <table class="center">
            <tr>
                <th *ngFor="let news of historyNews | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <div>
                        <mat-card class="example-card card-pagination">
                            <!-- <mat-card-header>
                            <div mat-card-avatar class="example-header-image"></div>
                            <mat-card-title>{{news.title}}</mat-card-title>
                            <mat-card-subtitle></mat-card-subtitle>
                        </mat-card-header> -->
                            <img mat-card-image [src]="news.image">
                            <mat-card-content>
                                <h6>{{news.title}}</h6>
                                <p class="caption">{{news.description}}</p>
                            </mat-card-content>

                            <mat-card-footer class="btn-read">
                                <a [href]="news.path" class="btn btn-primary" target="_blank">อ่านเพิ่มเติม</a>
                            </mat-card-footer>
                        </mat-card>
                    </div>
                </th>
            </tr>
        </table>
    </div>
    <br>

    <div class="pagination">
        <ngb-pagination [collectionSize]="historyNews.length" [(page)]="page" [pageSize]="pageSize">
        </ngb-pagination>
    </div>
</div>